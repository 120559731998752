<template>
  <div id="app">
    <ScaleBox :width="1920" :height="1080" bgc="transparent" :delay="100" :isFlat="true">
        <loading  v-if="lodingif==true" />
        <div v-else class="tcAPPcov">
          <iframe v-if="mapshow==true" src="http://pdmap.ilhzn.cn" frameborder="0" class="iframback" ></iframe>
          <img v-if="mapshow==false" src="@/assets/images/twoback.jpg" alt="" class="iframback">
          <!-- <iframe src="http://ycmap.sxcooh.com" frameborder="0" class="iframback" ></iframe> -->
          <div v-if="navlist==true" >
              <transition name="run" appear >
                  <nav>
                    <video width="100%" height="122"   autoplay="autoplay" muted="muted" loop="loop" class="bgvid">
                      <source src="@/assets/images/top.webm" type="video/webm">
                    </video>
                    <!-- <hefentianqi  class="weather" /> -->
                    <el-button type="text" @click="dialogVisible = true" class="weather" >
                       <iframe  width="300" scrolling="no" height="25" frameborder="0" allowtransparency="true" style="pointer-events: none;" src="https://i.tianqi.com?c=code&id=10&color=%23FFFFFF&icon=1&py=pingding&site=12"></iframe>
                    </el-button>
                    
                    <span class="time">{{nowTime}} <img src="@/assets/images/loginBack.png" alt="" class="loginBack" @click="bakLogin"> <span @click="bakLogin">退出</span> </span>
                    <span class="ycqtit" >平定县特优产业（谷子）数智农业大数据平台</span>
                    <router-link to="/" class="navone">
                      <div class="navone_tit">产业概况</div> 
                    </router-link> 
                    <router-link to="/about" class="navtwo">
                      <div class="navone_tit">产业生态</div> 
                    </router-link>
                    <router-link to="/zjzt" class="navthree">
                      <div class="navone_tit">产业发展</div> 
                    </router-link> 
                    <router-link to="/znwl" class="navfour">
                      <div class="navone_tit">交易追溯</div> 
                    </router-link>
                  </nav>
              </transition>
              <router-view />
          </div>
          <el-dialog
            title="提示"
            :visible.sync="dialogVisible"
            width="70%"
            :modal-append-to-body="false"
            :before-close="handleClose">
            <div class="tqtz_cov_chanye" style="height: 800px;position: relative;overflow-y:scroll;z-index: 9999;" @scroll="handleScroll">
                <div style="position: absolute;width:100%;height:185px;background:#2681C5;">
                  <img src="@/assets/images/tianqi.jpg" alt=""  />
                </div>
                <div class="lockAll" @click="lockAlls">
                    更多天气 >>
                </div>
                <iframe frameborder="0" allowtransparency="true" @load="fixIframeScrollPosition" ref="iframe112" style="width:100%;height:2900px;" src="http://www.nmc.cn/publish/forecast/ASX/pingding.html"></iframe>
            </div>
          </el-dialog>
        </div>
        
    </ScaleBox>
    <Login :style="denglu==false?'display:none;':'display:block'" />
  </div>
</template>

<script>
import ScaleBox from 'vue2-scale-box'
import loading from "./views/loading.vue";
import Login from "./views/Login/index.vue";
export default {
  name: 'App',
  components: {
    ScaleBox,
    loading,
    Login,
  },
  data(){
    return{
      lodingif:false,
      navlist:false,
      nowTime: "",
      mapshow:true,
      denglu:false,
      dialogVisible: false,
    }
  },
  created(){
    setTimeout(() => {
      this.navlist = true
    }, 10000); 
    if (localStorage.getItem('myname')=='pdadmin'&&localStorage.getItem('mypassword')=='pd2024') {
      this.denglu = false
      this.lodingif = true
    }else{
      this.denglu = true
    }
  },
  watch: {
    // 监听路由对象$route的变化
    '$route': {
      handler: function (to, from) {
        if(to.path == '/zjzt'){
          this.mapshow = false;
        }else if(to.path == '/about'){
          this.mapshow = false;
        }else if(to.path == '/znwl'){
          this.mapshow = false;
        }else{
          this.mapshow = true;
        }
      },
      // 设置为深度监听
      deep: true
    }
  },
  mounted() {
    // 在组件挂载后立即跳转到外部链接
    this.redirectToExternalPage();
    // 实时时间
    this.getNowTime();
  },
  methods:{
    // 天气弹窗
      handleClose(done) {
        this.dialogVisible = false
      },
      lockAlls(){
        window.open('https://www.tianqi.com/pingding/')
      },
      fixIframeScrollPosition() {
        const iframe112 = this.$refs.iframe112;
        if (iframe112) {
          // 假设你想要滚动到的位置
          const scrollTop = 500;
          iframe112.onload = () => {
            // 对于IE
            iframe112.contentWindow.document.body.scrollTop = scrollTop;
            // 对于其他浏览器
            iframe112.contentWindow.scrollTo(0, scrollTop);
          };
        }
      },
      handleScroll(e){
          let ostop = document.getElementsByClassName('tqtz_cov_chanye')[0].scrollTop
         console.log(ostop)
         if(ostop >= 1115){
          document.getElementsByClassName('tqtz_cov_chanye')[0].scrollTop = 1115

         }


        console.log(e)
      },
    // 天气结束
    bakLogin(){
      let name = ''
      let password = ''
      localStorage.setItem('myname', name);
      localStorage.setItem('mypassword', password);
      window.location.href= '/loading';
    },
    // 时间开始
    getNowTime() {
      let speed = 1000;
      let that = this;
      let theNowTime = function () {
        that.nowTime = that.timeNumber();
      };
      setInterval(theNowTime, speed);
    },
    timeNumber() {
      let today = new Date();
      let date =
        today.getFullYear() +
        "年" +
        this.twoDigits(today.getMonth() + 1) +
        "月" +
        this.twoDigits(today.getDate()) +
        "日";
      let time =
        this.twoDigits(today.getHours()) +
        ":" +
        this.twoDigits(today.getMinutes()) +
        ":" +
        this.twoDigits(today.getSeconds());
      let wk = new Date().getDay();
      let weeks = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      let week = weeks[wk];
      return date + "  " + week + "  " + time;
    },
    twoDigits(val) {
      if (val < 10) return "0" + val;
      return val;
    },
    // 时间结束
    redirectToExternalPage() {
      // 跳转到外部链接
      this.redirectHandler = setTimeout(() => {
        this.lodingif = false
        this.$router.push('/')
      }, 5000); 
    }
  },
  beforeDestroy() {
    // 组件销毁时清除跳转操作
    if (this.redirectHandler) {
      clearTimeout(this.redirectHandler);
    }
  }
}
</script>


<style lang="less">
@import "@/assets/css/common.css";
@import "@/assets/css/header.css";
body{
  background-color: #000;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.iframback{
  background: none;width:100%;height:1080px;position: absolute;z-index: -2;left:0;top:0;
}
.run-enter-active {
  animation: slideInDown 1s linear 0s ;
}
.run-leave-active {
  animation: lightSpeedOutRight 2s linear 0s;
}
.tqtz_cov_chanye{
	width: 100%;
	height: 670px;
	/* background: url(../../assets/images/tccovbak.png) no-repeat;
    background-size: 100% 100%; */
    background: rgba(15,36,69,0.7);
    border-radius: 0.5vw;
    border: 1px solid #339D94;
    text-align: center;
    overflow: hidden;
    box-shadow: 0px 0px 30px 0px rgba(14,252,255,0.8);
	position: relative;
	overflow: hidden;
  z-index: 99999999999999;
  .guanbiann{
    width: 1.5vw;
    height: 1.5vw;
    position: absolute;
    right: 2%;
    top: 0.5vw;
    z-index: 9999999999;
    cursor: pointer;
  }
  .lockAll{
    position: absolute;
    right: 50px;
    top: 120px;
    color: white;
    cursor: pointer;
    font-size: 15px;
}
  .tz_cov_top_tit{
    font-size: 1vw;
      font-family: douyuFont;
      color: #fff;
      text-align: center;
  }
}
/* 定义滚动条样式 */
.tqtz_cov_chanye::-webkit-scrollbar {
	width: 2px;
	height: 2px;
	background-color: rgba(240, 240, 240, 0.1);
}
/*定义滚动条轨道 内阴影+圆角*/
.tqtz_cov_chanye::-webkit-scrollbar-track {
	box-shadow: inset 0 0 0px rgba(240, 240, 240, .0);
	border-radius: 10px;
	background-color: rgba(240, 240, 240, .0);
}
/*定义滑块 内阴影+圆角*/
.tqtz_cov_chanye::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 0px rgba(36, 132, 109, .8);
	background-color: rgba(36, 132, 109, .8);
}

.tcAPPcov{
  .el-dialog, .el-pager li{
			background: none;
		}
		::v-deep .el-carousel__mask {
			display: none;
		}
		::v-deep .el-dialog {
			// position: relative;
			// margin: 0 auto;
			background: none !important;
      height: 690px;
      overflow: hidden;
			// border-radius: 2px;
			// box-shadow: 0 1px 3px rgba(0,0,0,.3);
			// box-sizing: border-box;
			// width: 50%;
			/* margin-left: 22%; */
		}
		::v-deep .el-dialog__header {
			display: none !important;
		}
		::v-deep .carouse .el-carousel__item--card {
			width: 65% !important;
			margin-left: -40px;
		}
    .el-dialog {
        position: relative;
        margin: 0 auto;
        background: none;
        border-radius: 2px;
        box-shadow: 0 1px 3px rgba(0,0,0,.3);
        box-sizing: border-box;
        width: 50%;
        /* margin-left: 22%; */
    }
    .el-dialog__header{
      display: none;
    }
}

</style>
